export const monthsData = [
  { name: 'January', requiredHours: 0, workingHours: 0, overtimeHours: 0, sickLeaves: 0, holidays: 0 },
  { name: 'February', requiredHours: 0, workingHours: 0, overtimeHours: 0, sickLeaves: 0, holidays: 0 },
  { name: 'March', requiredHours: 0, workingHours: 0, overtimeHours: 0, sickLeaves: 0, holidays: 0 },
  { name: 'April', requiredHours: 0, workingHours: 0, overtimeHours: 0, sickLeaves: 0, holidays: 0 },
  { name: 'May', requiredHours: 0, workingHours: 0, overtimeHours: 0, sickLeaves: 0, holidays: 0 },
  { name: 'June', requiredHours: 0, workingHours: 0, overtimeHours: 0, sickLeaves: 0, holidays: 0 },
  { name: 'July', requiredHours: 0, workingHours: 0, overtimeHours: 0, sickLeaves: 0, holidays: 0 },
  { name: 'August', requiredHours: 0, workingHours: 0, overtimeHours: 0, sickLeaves: 0, holidays: 0 },
  { name: 'September', requiredHours: 0, workingHours: 0, overtimeHours: 0, sickLeaves: 0, holidays: 0 },
  { name: 'October', requiredHours: 0, workingHours: 0, overtimeHours: 0, sickLeaves: 0, holidays: 0 },
  { name: 'November', requiredHours: 0, workingHours: 0, overtimeHours: 0, sickLeaves: 0, holidays: 0 },
  { name: 'December', requiredHours: 0, workingHours: 0, overtimeHours: 0, sickLeaves: 0, holidays: 0 }
  ];
  
