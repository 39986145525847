<template>
 <div class="color-legend">
    <div class="color-item yel"></div>
    <div class="legend-label">Public Holiday</div>
    <div class="color-item gra"></div>
    <div class="legend-label">Weekend</div>
    <div class="color-item re"></div>
    <div class="legend-label">Sick Leave</div>
    <div class="color-item blu"></div>
    <div class="legend-label">Vacation</div>
    <div class="color-item orange"></div>
    <div class="legend-label">School/University</div>
  </div>
</template>

<script>
export default {
  name: 'Legend',

};
</script>
