<template>
  <div class="excel">
    <b-button class="aaa" type="button" v-on:click="exportToExcel">Export as Excel</b-button>
  </div>
  <div>
    <div class="container">
      <div class="col-md-4">
        <b-select id="yearSelect" class="form-select" v-model="selectedYear" @change="filterByYear">
          <option v-for="year in yearsDropdown" :value="year">{{ year }}</option>
        </b-select>
      </div>
    </div>
    <b-table class="overviewtable" striped hover :items="filteredItems" :fields="fields" ref="table">

      <template #cell(progress)="{ item }">
        <div class="progress" style="height: 20px;">
          <div v-if="percentageWorked(item) <= 100" class="progress-bar progress-bar-orange"
               role="progressbar"
               :style="{ width: percentageWorked(item) + '%' }"
               aria-valuemin="0"
               aria-valuemax="100">
            {{ percentageWorked(item).toFixed(0) }}%
          </div>
          <div v-else class="progress-bar progress-bar-orange"
               role="progressbar"
               :style="{ width: '100%' }"
               aria-valuemin="0"
               aria-valuemax="100">
            100%
          </div>
          <div v-if="percentageWorked(item) > 100" class="progress-bar progress-bar-green"
               role="progressbar"
               :style="{ width: (percentageWorked(item) - 100) + '%' }"
               aria-valuemin="0"
               aria-valuemax="100">
            {{ (percentageWorked(item) - 100).toFixed(0) }}%
          </div>
        </div>
      </template>

      <template #custom-foot>
        <tr style="color: #E2923D; font-size: 20px;">
          <td>Total</td>
          <td>{{ totalRequiredHours }}</td>
          <td>{{ totalWorkingHours }}</td>
          <td>{{ totalOvertimeHours }}</td>
          <td>{{ totalHolidaysCount }}</td>
          <td>{{ totalSickLeavesCount }}</td>
          <td style="width: 35%;"> 
            <div class="progress" style="height: 20px;">
              <div v-if="totalProgressBar() <= 100" class="progress-bar progress-bar-orange"
                   role="progressbar"
                   :style="{ width: totalProgressBar() + '%' }"
                   aria-valuemin="0"
                   aria-valuemax="100">
                Total Progress {{ totalProgressBar().toFixed(0) }}%
              </div>
              <div v-else class="progress-bar progress-bar-orange"
                   role="progressbar"
                   :style="{ width: '100%' }"
                   aria-valuemin="0"
                   aria-valuemax="100">
                100%
              </div>
              <div v-if="totalProgressBar() > 100" class="progress-bar progress-bar-green"
                   role="progressbar"
                   :style="{ width: (totalProgressBar() - 100) + '%' }"
                   aria-valuemin="0"
                   aria-valuemax="100">
                {{ (totalProgressBar() - 100).toFixed(0) }}%
              </div>
            </div>
          </td>
        </tr>
      </template>
    </b-table>
  </div>
</template>



<script>
import * as XLSX from 'xlsx'

export default {
  name: 'Overview',
  data() {
    const currentYear = new Date().getFullYear();

    return {
      list: undefined,
      fields: [
        { key: 'attributes.month', label: 'Month' },
        { key: 'attributes.totalRequiredHours', label: 'Required Hours' },
        { key: 'attributes.totalWorkingHours', label: 'Working Hours' },
        { key: 'attributes.totalOvertimeHours', label: 'Overtime' },
        { key: 'attributes.holidaysCount', label: 'Holidays' },
        { key: 'attributes.sickLeavesCount', label: 'Sick Leaves' },
        { key: 'progress', label: 'Progress' }
      ],
      selectedYear: currentYear,  
      yearsDropdown: [],
      totalRequiredHours: 0,
      totalWorkingHours: 0,
      totalOvertimeHours: 0,
      totalHolidaysCount: 0,
      totalSickLeavesCount: 0,
      requiredHours: { Monday: 8, Tuesday: 8, Wednesday: 8, Thursday: 8, Friday: 8, overHoursPerMonth: 8 },

    };
  },
  computed: {
    uniqueYears() {
      const years = new Set();
      if (this.list) {
        this.list.forEach((item) => {
          years.add(item.attributes.year);
        });
      }
      return Array.from(years);
    },
    yearsDropdown() {
      return this.uniqueYears.sort(); // Sort the years in ascending order
    },
    filteredItems() {
      if (this.list && this.selectedYear) {
        const monthOrder = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ];

        return this.list
          .filter((item) => item.attributes.year === this.selectedYear)
          .sort((a, b) => {
            const monthA = monthOrder.indexOf(a.attributes.month);
            const monthB = monthOrder.indexOf(b.attributes.month);
            return monthA - monthB;
          });
      }
      return this.list;
    },
    
  },
  methods: {
    getOverview() {
      this.$xhr.get(`/api/overviews?populate=*&filters[user_id][$eq]=${this.$route.params.id}`).then((result) => {
        console.log(result, 'xxxxxxxxxxx');
        this.list = result.data.data;
        this.calculateTotals();
      });
    },
    calculateTotals() {
      this.totalRequiredHours = this.filteredItems.reduce(
        (acc, item) => acc + parseFloat(item.attributes.totalRequiredHours),
        0
      );
      this.totalWorkingHours = this.filteredItems.reduce(
        (acc, item) => acc + parseFloat(item.attributes.totalWorkingHours),
        0
      );
      this.totalOvertimeHours = this.filteredItems.reduce(
        (acc, item) => acc + parseFloat(item.attributes.totalOvertimeHours),
        0
      );
      this.totalHolidaysCount = this.filteredItems.reduce(
        (acc, item) => acc + item.attributes.holidaysCount,
        0
      );
      this.totalSickLeavesCount = this.filteredItems.reduce(
        (acc, item) => acc + item.attributes.sickLeavesCount,
        0
      );
    },
    filterByYear() {
      this.calculateTotals();
    },
    reloadData() {
      this.$xhr.get(`/api/overviews?filters[user_id][$eq]=${this.$route.params.id}`)
        .then((resp) => {
          this.list = resp.data.data
        })
    },
    exportToExcel() {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.table_to_sheet(this.$refs.table.$el);
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'table.xlsx');
    },
    percentageWorked(item) {
  if (!item || !item.attributes) {
    return 0; // Return 0 if item or item.attributes is undefined
  }

  const totalRequiredHours = parseFloat(item.attributes.totalRequiredHours);
  const totalWorkingHours = parseFloat(item.attributes.totalWorkingHours);
  const overHoursPerMonth = parseFloat(this.requiredHours.overHoursPerMonth);

  if (!isNaN(totalRequiredHours) && !isNaN(totalWorkingHours)) {
    if (totalWorkingHours <= totalRequiredHours) {
      return (totalWorkingHours )/ (totalRequiredHours) * 100;
    } else if (totalWorkingHours <= totalRequiredHours + overHoursPerMonth) {
      return 100;
    } else {
      return (totalWorkingHours) / (totalRequiredHours+ overHoursPerMonth) * 100;
    }
  }

  return 0;
},
totalProgressBar() {
  if (!this.filteredItems || this.filteredItems.length === 0) {
    return 0; // Return 0 if filteredItems is undefined or empty
  }

  const totalPercentage = this.filteredItems.reduce((acc, item) => {
    return acc + this.percentageWorked(item);
  }, 0);

  return totalPercentage / this.filteredItems.length;
},


    getHours() {
    this.$xhr.get(`/api/fixhours?populate=*&filters[user_id][$eq]=${this.$route.params.id}`).then((response) => {
      let hoursData = response.data.data;
      hoursData = JSON.parse(JSON.stringify(hoursData))
      if (Array.isArray(hoursData) && hoursData.length > 0) {
        const requiredHours = hoursData[0].attributes.requiredHours;
        this.requiredHours = requiredHours;
        console.log(this.requiredHours, 'sss')
      }
    });
    },
  },

  mounted() {
    this.reloadData();
    this.getOverview();
    this.getHours();
  },
};
</script>

<style scoped>
.overviewtable {
  margin-left: 0px;
  margin-top: 20px;
}

.progress-bar-orange {
  background-color: #E2923D;
}

.progress-bar-green {
  background-color: green;
}
.container {
    margin-left: -12px;
    width: 500px;
    margin-top: -30px;
}
</style>
