<template>
   <div class="excel">
    <b-button type="button" variant="success" v-on:click="addFixhours()">Save</b-button>
  </div>
  <b-table-simple class="General table-style">
    <b-thead>
      <b-tr>
        <b-th>Day</b-th>
        <b-th>Hours</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr>
        <b-td>Monday:</b-td>
        <b-td>
          <input type="number" id="monday-hours" v-model="requiredHours.Monday" class="input-style" />
        </b-td>
      </b-tr>
      <b-tr>
        <b-td>Tuesday:</b-td>
        <b-td>
          <input type="number" id="Tuesday-hours" v-model="requiredHours.Tuesday" class="input-style" />
        </b-td>
      </b-tr>
      <b-tr>
        <td>Wednesday:</td>
        <td>
          <input type="number" id="Wednesday-hours" v-model="requiredHours.Wednesday" class="input-style" />
        </td>
      </b-tr>
      <b-tr>
        <b-td>Thursday:</b-td>
        <b-td>
          <input type="number" id="Thursday-hours" v-model="requiredHours.Thursday" class="input-style" />
        </b-td>
      </b-tr>
      <b-tr>
        <b-td>Friday:</b-td>
        <b-td>
          <input type="number" id="Friday-hours" v-model="requiredHours.Friday" class="input-style" />
        </b-td>
      </b-tr>
      <b-tr>
        <b-td>Monthly overtime hours included in contract:
          <b-td class="description-cell">See §6- Überstunden und Mehrarbeit and according to § 4 Abs.(1) </b-td>
        </b-td>
        <b-td>
          <input type="number" v-model="requiredHours.overHoursPerMonth" class="input-style" />
        </b-td>
      </b-tr>
      <b-tr>
        <b-td>Hours per week:</b-td>
        <b-td>{{ totalHoursPerWeek }}</b-td>
      </b-tr>
      <b-tr>
      <b-td>State:</b-td>
      <b-td>
        <select v-model="state" class="input-style">
          <option v-for="stateOption in stateOptions" :key="stateOption">{{ stateOption }}</option>
        </select>
      </b-td>
    </b-tr>

    </b-tbody>
  </b-table-simple>
 
  <div>
    <Userprofile />
  </div>
</template>


<script>
import Userprofile from '../components/Userprofile.vue';
export default {
  name: "General",
  components: {
    Userprofile
  },
  data() {
    return {
      id: null,
      state: "Berlin", 
      stateOptions: [
      "Baden-Württemberg",
      "Bayern",
      "Berlin",
      "Brandenburg", 
      "Bremen",
      "Hamburg",
      "Hessen",
      "Niedersachsen",
      "Mecklenburg-Vorpommern",
      "Nordrhein-Westfalen",
      "Rheinland-Pfalz",
      "Saarland",
      "Sachsen",
      "Sachsen-Anhalt",
      "Schleswig-Holstein",
      "Thüringen"
      ],
      requiredHours: {
        Monday: 8,
        Tuesday: 8,
        Wednesday: 8,
        Thursday: 8,
        Friday: 8,
        overHoursPerMonth: 8,
      },
      fixhoursData: []
    };
  },
  computed: {
    totalHoursPerWeek() {
      const { overHoursPerMonth, ...rest } = this.requiredHours;
      const hours = Object.values(rest);
      const total = hours.reduce((sum, hour) => sum + hour, 0);
      return total;
    },
  },

  async mounted() {
    const { data: { data } } = await this.$xhr.get(`/api/fixhours?populate=*&filters[user_id][$eq]=${this.$route.params.id}`);
    console.log('DATA', data);
    this.fixhoursData = data; 

    if (data.length > 0) {
      const { id, attributes: { requiredHours, state } } = data[0];
      this.requiredHours = requiredHours;
      this.state = state;
      this.id = id;
    }
  },

  methods: {
    async addFixhours() {
      const { Monday, Tuesday, Wednesday, Thursday, Friday, overHoursPerMonth } = this.requiredHours;
      const requiredHours = { Monday, Tuesday, Wednesday, Thursday, Friday, overHoursPerMonth };
      const payload = {
        requiredHours,
        state: this.state, 
        user_id: this.$route.params.id,
        users_permissions_user: { id: this.$route.params.id } 
      };

      const existingEntry = this.fixhoursData.find(entry => entry.attributes.users_permissions_user.data.id == this.$route.params.id);

      if (existingEntry) {
        const { id } = existingEntry;
        const { data: { data: { attributes: { requiredHours, state } } } } = await this.$xhr.put(`api/fixhours/${id}`, { data: payload });
        this.requiredHours = requiredHours;
        this.state = state;
        existingEntry.attributes.requiredHours = requiredHours;
        existingEntry.attributes.state = state;
        this.makeUpdateToast(true);
      } else {
        const { data: { data: { attributes: { requiredHours, state } } } } = await this.$xhr.post("api/fixhours", { data: payload });
        this.requiredHours = requiredHours;
        this.state = state;
        this.fixhoursData.push({ attributes: { requiredHours, state, users_permissions_user: { data: { id: this.$route.params.id } } } });
        this.makeToast(true);
      }
      
    },
    makeToast(append = false) {
      this.$bvToast.toast("Data Successfully added!", {
        title: "Success",
        variant: "success",
        solid:true,
      });
    },
    makeUpdateToast() {
    this.$bvToast.toast("Data Successfully updated!", {
      title: "Success",
      variant: "success",
      solid: true,
    });
  },
  },
}
</script>

<style scoped>
.description-cell {
  font-size: 10px;
  color: #999;
  border-color: white;
  text-align: center;
}
.General {
    margin-top: 67px;
    margin-left: 546px;
    width: 30%
}
</style>

