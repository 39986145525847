<template>
  <div class="container">
    <!-- Upload Form Card -->
    <div class="card">
      <div class="card-header bg-warning text-white">
        <h1 class="my-4">Upload Documents</h1>
      </div>
      <div class="card-body">
        <form @submit.prevent="uploadDocuments">
          <div class="mb-3">
            <label for="fileInput" class="form-label">Select Documents (PDF)</label>
            <input type="file" class="form-control" id="fileInput" @change="handleFileChange" accept="application/pdf" multiple />
          </div>
          <button type="submit" class="btn btn-primary">Upload</button>
        </form>
      </div>
    </div>

    <!-- Progress Bar -->
    <div v-if="uploading" class="mt-3">
      <div class="card">
        <div class="card-body">
          <h3>Uploading...</h3>
          <div class="progress">
            <div class="progress-bar bg-success" role="progressbar" :style="{ width: uploadProgress + '%' }" :aria-valuenow="uploadProgress" aria-valuemin="0" aria-valuemax="100">
              {{ uploadProgress }}%
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Uploaded Files -->
    <div v-if="uploadedFiles.length > 0" class="mt-3">
      <div class="card">
        <div class="card-body">
          <h3>Files sent to Sproof ({{ uploadedFiles.length }})</h3>
          <ul>
            <li v-for="(file, index) in uploadedFiles" :key="index">{{ file.name }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedFiles: [],
      uploading: false,
      uploadProgress: 0,
      uploadResponse: null,
      uploadedFiles: [],
    };
  },
  methods: {
    handleFileChange(event) {
      this.selectedFiles = Array.from(event.target.files);
    },
    async uploadDocuments() {
      if (this.selectedFiles.length === 0) {
        alert('Please select one or more documents to upload.');
        return;
      }

      this.uploading = true;
      this.uploadProgress = 0;

      const totalFiles = this.selectedFiles.length;
      let uploadedCount = 0;

      const uploadPromises = this.selectedFiles.map(async (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        return new Promise((resolve, reject) => {
          reader.onload = async () => {
            const base64File = reader.result.split(',')[1]; // Extract base64 part from data URL

            const requestData = {
              data: base64File,
              fileName: file.name,
              email: 'accounting@time-change.services',
              firstName: 'Time Change Service & Logistik',
              lastName: 'GmbH',
              usePlaceholders: true, // Include usePlaceholders as instructed
            };

            try {
              const response = await this.$xhr.post(
                'https://sign.sproof.io/api/v1/documents',
                requestData,
                {
                  params: {
                    token: 'de135bea-810a-42ad-be28-878bc2c6db97',
                  },
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  onUploadProgress: (progressEvent) => {
                    // Calculate upload progress
                    this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                  },
                }
              );

              if (response.status === 200) {
                this.uploadResponse = `Successfully uploaded ${file.name}`;
                this.uploadedFiles.push(file); // Store successfully uploaded file
                uploadedCount++;
                if (uploadedCount === totalFiles) {
                  this.uploading = false;
                  this.uploadProgress = 0;
                }
                resolve();
              } else {
                this.uploadResponse = `Failed to upload ${file.name}: ${response.statusText}`;
                reject(`Failed to upload ${file.name}`);
              }
            } catch (error) {
              console.error('Error uploading document:', error);
              this.uploadResponse = `Error uploading ${file.name}: ${error.response ? error.response.data : error.message}`;
              reject(`Error uploading ${file.name}`);
            }
          };

          reader.onerror = (error) => {
            alert('Error reading file: ' + error.message);
            reject(`Error reading ${file.name}`);
          };
        });
      });

      try {
        await Promise.all(uploadPromises);
      } catch (error) {
        console.error('Error uploading documents:', error);
      }
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 20px;
}
.card {
  margin-bottom: 20px;
}
.progress {
  margin-top: 10px;
}
.bg-warning{
  background-color: #E2923D;
}

</style>
