<template>
  <div class="excel">

    <b-button-group class="mx-1">
      <b-button class="aaa" variant="success" type="button" v-on:click="handleButtonClick()">Save</b-button>
    </b-button-group>
    <b-button-group class="mx-1">
      <b-button class="aaa" type="button" v-on:click="exportToExcel">Export as Excel</b-button>
    </b-button-group>
  </div>
  <b-select v-model="selectedYear" class="year-dropdown" @change="updateRoute">
    <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
  </b-select>
  <b-select v-model="selectedMonth" class="month-dropdown" @change="updateRoute">
    <option v-for="(month, index) in months" :key="index" :value="index">{{ month.name }}</option>
  </b-select>
  <div class="progress" style="height: 20px;">
    <div class="progress-bar progress-bar-orange" role="progressbar"
      :style="{ width: Math.min(percentageWorked, 100) + '%' }" aria-valuenow="percentageWorked" aria-valuemin="0"
      aria-valuemax="100">
      Progress {{ Math.min(percentageWorked, 100).toFixed(0) }}%
    </div>
    <div class="progress-bar progress-bar-green" role="progressbar" v-if="percentageWorked > 100"
      :style="{ width: (percentageWorked - 100) + '%' }" aria-valuenow="percentageWorked" aria-valuemin="0"
      aria-valuemax="200">
      {{ (percentageWorked - 100).toFixed(0) }}%
    </div>
  </div>
  <b-table-simple sticky-header class="table-style" ref="table">
    <thead>
      <tr>
        <th>Date</th>
        <th>Day</th>
        <th>Start Time</th>
        <th>End Time</th>
        <th>Break</th>
        <th>Required Hours</th>
        <th>Working Hours</th>
        <th>*Overtime Hours</th>
        <th>Reason</th>
        <th class="custom-status-header" style="display: none;"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="date in getDatesForSelectedMonth()" :key="date" :class="{
        'yellow-row': isPublicHoliday(date, state) || status[date] === 'Public Holiday',
        'gray-row': isWeekend(date),
        'red-row': status[date] === 'Sick',
        'blue-row': status[date] === 'Vacation',
        'orange-row': status[date] === 'School',
      }">
        <td>{{ date }}</td>
        <td>{{ getDayOfWeek(date) }}</td>
        <td>
          <input type="text" placeholder="HH:mm" required="required" v-model="startTimes[date]" class="input-style" />
        </td>
        <td>
          <input type="text" placeholder="HH:mm" required="required" v-model="endTimes[date]" class="input-style" />
        </td>
        <td>
          <input type="number" placeholder="Minutes" v-model="breaks[date]" class="input-style" />
        </td>
        <td :class="{ 'public-holiday': isPublicHoliday(date, state) }">{{ getRequiredHours(date) }}</td>
        <td>{{ calculateWorkingHours(date) }}</td>
        <td>{{ calculateOvertimeHours(date) }}</td>
        <td>
          <select v-model="status[date]" class="input-style">
            <option value="Normal">Normal</option>
            <option value="Sick" :disabled="getRequiredHours(date) === '0h 0m'">Sick</option>
            <option value="Vacation" :disabled="getRequiredHours(date) === '0h 0m'">Vacation</option>
            <option value="Public Holiday">Public Holiday</option>
            <option value="Overtime reduction">Overtime reduction</option>
            <option value="School" :disabled="getRequiredHours(date) === '0h 0m'">School/University</option>
            <option value="Free">Free</option>
            <option value="Custom">Custom</option>
          </select>
          <input type="text" v-if="status[date] === 'Custom'" v-model="customStatus[date]" class="input-style" />


        </td>

      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th colspan="6">Total Required Hours</th>
        <th>{{ totalRequiredHours }}</th>
      </tr>
      <tr>
        <th colspan="6">Total Working Hours</th>
        <th>{{ totalWorkingHours }}</th>
        <th>{{ totalOvertimeHours }}</th>
      </tr>
      <tr>
        <th colspan="6">Sick Leaves</th>
        <th>{{ sickLeavesCount }}</th>
        <th colspan="2"></th>
      </tr>
      <tr>
        <th colspan="6">Vacation Count</th>
        <th>{{ holidaysCount }}</th>
        <th colspan="2"></th>
      </tr>
    </tfoot>
  </b-table-simple>
  <Legend />
  <div class="legends">
    <span class="legend-item">*Monthly Overtime hours accounts the unpaid overtime hours specified in the employment
      contract.</span>
  </div>
</template>

<script>
import General from './General.vue';
import Legend from '../components/Legend.vue';
import { months } from '../helper/months';
import { monthsData } from '../helper/monthsData.js';
import { logoBase64 } from '../helper/logo.js';
import * as moment from 'moment-feiertage';
import ExcelJS from 'exceljs';

export default {
  name: 'Months',
  components: {
    General,
    Legend
  },
  data() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    return {
      showOverview: false,
      list: undefined,
      years: [2024, 2025, 2026, 2027, 2028, 2029, 2030], // Add more years as needed
      selectedYear: currentYear,
      startTimes: {},
      endTimes: {},
      breaks: {},
      status: {},
      customStatus: {},
      months: months,
      monthsData: monthsData,
      selectedMonth: currentMonth,
      hoursPerWeek: 40,
      requiredHours: { Monday: 8, Tuesday: 8, Wednesday: 8, Thursday: 8, Friday: 8, overHoursPerMonth: 8 },
      allrequiredhours: [],
      state: '',
      isToastVisible: false,
      userEmail: '',
      

    };
  },
  computed: {
    percentageWorked() {
      const totalRequiredHours = parseFloat(this.totalRequiredHours);
      const totalWorkingHours = parseFloat(this.totalWorkingHours);
      const overHoursPerMonth = parseFloat(this.requiredHours.overHoursPerMonth);

      if (!isNaN(totalRequiredHours) && !isNaN(totalWorkingHours)) {
        if (totalWorkingHours <= totalRequiredHours) {
          return (totalWorkingHours) / (totalRequiredHours) * 100;
        } else if (totalWorkingHours <= totalRequiredHours + overHoursPerMonth) {
          return 100;
        } else {
          return (totalWorkingHours) / (totalRequiredHours + overHoursPerMonth) * 100;
        }
      }

      return 0;
    },
    totalRequiredHours() {
      let totalMinutes = 0;
      const dates = this.getDatesForSelectedMonth();

      for (const date of dates) {
        if (!this.isWeekendOrPublicHoliday(date) && this.getRequiredHours(date) !== 'PH') {
          const requiredHours = this.getRequiredHours(date);
          const [hours, minutes] = requiredHours.split(/[hm]/).filter(Boolean);

          totalMinutes += parseInt(hours, 10) * 60 + parseInt(minutes, 10);
        }
      }

      // Convert total minutes back to hours and round to two decimal places
      const roundedTotalHours = Math.round((totalMinutes / 60) * 100) / 100;

      this.monthsData[this.selectedMonth].requiredHours = roundedTotalHours.toFixed(2);

      return roundedTotalHours.toFixed(2);
    },
    totalWorkingHours() {
      let totalHours = 0;
      const dates = this.getDatesForSelectedMonth();

      for (const date of dates) {
        const workingHours = this.calculateWorkingHours(date);

        if (workingHours !== 'Invalid time' && workingHours !== '') {
          const hours = parseInt(workingHours.split('h')[0]);
          const minutes = parseInt(workingHours.split('h')[1].split('m')[0]);

          totalHours += hours;
          totalHours += minutes / 60;
        }
      }
      this.monthsData[this.selectedMonth].workingHours = totalHours.toFixed(2);
      return totalHours.toFixed(2);
    },
    totalOvertimeHours() {
      const totalRequiredHours = parseFloat(this.totalRequiredHours);
      const totalWorkingHours = parseFloat(this.totalWorkingHours);

      if (!isNaN(totalRequiredHours) && !isNaN(totalWorkingHours)) {
        let totalOvertime = totalWorkingHours - totalRequiredHours;

        if (totalOvertime > this.requiredHours.overHoursPerMonth) {
          totalOvertime -= this.requiredHours.overHoursPerMonth;
        } else if (totalOvertime < 0) {
          // If totalWorkingHours < totalRequiredHours, show the difference in negative
          this.monthsData[this.selectedMonth].overtimeHours = totalOvertime.toFixed(2);
          return totalOvertime.toFixed(2);
        } else {
          totalOvertime = 0;
        }

        this.monthsData[this.selectedMonth].overtimeHours = totalOvertime.toFixed(2);
        return totalOvertime.toFixed(2);
      }

      return '';
    },
    sickLeavesCount() {
      let count = 0;
      const dates = this.getDatesForSelectedMonth();

      for (const date of dates) {
        if (this.status[date] === 'Sick' && !this.isWeekend(date)) {
          count++;
        }
      }
      this.monthsData[this.selectedMonth].sickLeaves = count;

      return count;
    },
    holidaysCount() {
      let count = 0;
      const dates = this.getDatesForSelectedMonth();

      for (const date of dates) {
        if (this.status[date] === 'Vacation' && !this.isWeekend(date)) {
          count++;
        }
      }
      this.monthsData[this.selectedMonth].holidays = count;

      return count;
    },
  },
  async created() {
    if (this.$route.params.month) {
      this.selectedMonth = parseInt(this.$route.params.month) - 1;
    }
    await this.getUser();
  },

watch: {
  breaks: {
    handler(newBreaks) {
      const thresholdDate = new Date('2024-09-01');

      Object.keys(newBreaks).forEach((date) => {
        const dateObj = new Date(date);
        
        if (dateObj >= thresholdDate) {
          const calculatedBreak = this.calculateBreak(date);
          if (newBreaks[date] < calculatedBreak) {
            this.breaks[date] = calculatedBreak;
          }
        }
      });
    },
    deep: true
  }
},
  

  methods: {
      calculateWorkingHours(date) {
      const start = this.startTimes[date];
      const end = this.endTimes[date];

      if (!start || !end) {
        return ''; // Return empty string if start or end time is not provided
      }

      // Parse start and end times into hours and minutes
      const [startHourStr, startMinuteStr] = start.split(':');
      const [endHourStr, endMinuteStr] = end.split(':');

      // Convert start and end hours to numbers
      const startHour = parseInt(startHourStr, 10);
      const endHour = parseInt(endHourStr, 10);

      // Pad the minutes with leading zero if necessary
      const startMinute = parseInt(startMinuteStr, 10);
      const endMinute = parseInt(endMinuteStr, 10);

      // Convert start and end times into total minutes
      const startTotalMinutes = startHour * 60 + startMinute;
      const endTotalMinutes = endHour * 60 + endMinute;

      // Calculate working minutes
      let workingMinutes = endTotalMinutes - startTotalMinutes;

      // Check if working minutes is negative or zero
      if (workingMinutes <= 0) {
        return 'Invalid time'; // Return 'Invalid time' if the calculated working minutes is negative or zero
      }

      // Get break duration
      let breakDuration = this.breaks[date];

      // If break duration is not set, calculate it based on working minutes
      if (breakDuration === undefined || breakDuration === null) {
        if (workingMinutes < 360) {
          breakDuration = 0; // Set break to 0 minutes if working hours are less than 6
        } else if (workingMinutes >= 360 && workingMinutes <= 539) {
          breakDuration = 30; // Set break to 30 minutes if working hours are between 6 and 9 (both inclusive)
        } else if (workingMinutes >= 540) {
          breakDuration = 45; // Set break to 45 minutes if working hours are greater than or equal to 9
        }

        this.breaks[date] = breakDuration; // Set the break duration in the breaks object
      }

      // Deduct break duration from working minutes
      workingMinutes -= breakDuration;

      // Check if working minutes is negative or zero after deducting break
      if (workingMinutes <= 0) {
        return 'Invalid time'; // Return 'Invalid time' if the calculated working minutes after deducting break is negative or zero
      }

      // Calculate hours and minutes from working minutes
      const hours = Math.floor(workingMinutes / 60);
      const minutes = workingMinutes % 60;

      // Return the calculated working hours
      return `${hours}h ${minutes}m`;
    },
    calculateBreak(date) {
    const start = this.startTimes[date];
    const end = this.endTimes[date];

    if (!start || !end) {
      return 0;
    }

    // Parse start and end times
    const [startHour, startMinute] = start.split(':').map(Number);
    const [endHour, endMinute] = end.split(':').map(Number);

    const startTotalMinutes = startHour * 60 + startMinute;
    const endTotalMinutes = endHour * 60 + endMinute;

    const workingMinutes = endTotalMinutes - startTotalMinutes;

    // Calculate break time
    if (workingMinutes < 360) {
      return 0;
    } else if (workingMinutes >= 360 && workingMinutes <= 539) {
      return 30;
    } else if (workingMinutes >= 540) {
      return 45;
    }
  },
    updateRoute() {
      const newRoute = {
        name: 'Months',
        params: {
          id: this.$route.params.id,
          year: this.selectedYear,
          month: this.selectedMonth + 1,
        },
      };
      this.$router.replace(newRoute);
    },
    formatName(userEmail) {
      const parts = userEmail.split('@')[0].split('.')
      const formattedName = parts.map((part) => part.charAt(0).toUpperCase() + part.slice(1)).join(' ')
      return `${formattedName}`
    },
    exportToExcel() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet1', {
        pageSetup: {
          orientation: 'landscape',
          paperSize: 9,
          fitToPage: true,
          fitToWidth: 1,
          fitToHeight: 0,
        },
      });
      const headerStyle = {
        font: { bold: true },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFE2923D' } },
      };
      const footerStyle = {
        font: { bold: true },
      };
      worksheet.getCell('A1').value = 'Date';
      worksheet.getCell('A1').style = headerStyle;
      worksheet.getCell('B1').value = 'Day';
      worksheet.getCell('B1').style = headerStyle;
      worksheet.getCell('C1').value = 'Start Time';
      worksheet.getCell('C1').style = headerStyle;
      worksheet.getCell('D1').value = 'End Time';
      worksheet.getCell('D1').style = headerStyle;
      worksheet.getCell('E1').value = 'Break';
      worksheet.getCell('E1').style = headerStyle;
      worksheet.getCell('F1').value = 'Required Hours';
      worksheet.getCell('F1').style = headerStyle;
      worksheet.getCell('G1').value = 'Working Hours';
      worksheet.getCell('G1').style = headerStyle;
      worksheet.getCell('H1').value = 'Overtime Hours';
      worksheet.getCell('H1').style = headerStyle;
      worksheet.getCell('I1').value = 'Reason';
      worksheet.getCell('I1').style = headerStyle;
      worksheet.getCell('J1').value = 'Custom Reason';
      worksheet.getCell('J1').style = headerStyle;

      const dates = this.getDatesForSelectedMonth();
      for (let i = 0; i < dates.length; i++) {
        const date = dates[i];
        const row = i + 2; // Start from row 2 to skip headers
        const startTime = this.startTimes[date];
        const endTime = this.endTimes[date];
        const breaks = this.breaks[date] !== null ? `${this.breaks[date]} m` : '';
        const status = this.status[date];
        const customStatus = this.customStatus[date];

        worksheet.getCell(`A${row}`).value = date;
        worksheet.getCell(`B${row}`).value = this.getDayOfWeek(date);
        worksheet.getCell(`C${row}`).value = startTime !== null ? startTime : '';
        worksheet.getCell(`D${row}`).value = endTime !== null ? endTime : '';
        worksheet.getCell(`E${row}`).value = breaks !== 'undefined m' ? breaks : '';
        worksheet.getCell(`F${row}`).value = this.getRequiredHours(date);
        worksheet.getCell(`G${row}`).value = this.calculateWorkingHours(date);
        worksheet.getCell(`H${row}`).value = this.calculateOvertimeHours(date);
        worksheet.getCell(`I${row}`).value = status !== null ? status : '';
        worksheet.getCell(`J${row}`).value = customStatus !== null && customStatus !== 0 ? customStatus : '';
      }
      // Calculate totals
      const totalRequiredHours = this.totalRequiredHours;
      const totalWorkingHours = this.totalWorkingHours;
      const totalOvertimeHours = this.totalOvertimeHours;
      const sickLeavesCount = this.sickLeavesCount;
      const holidaysCount = this.holidaysCount;
      const formattedName = this.formatName(this.userEmail);
      // Add labels for totals
      worksheet.getCell(`A${dates.length + 6}`).value = 'Date:';
      worksheet.getCell(`A${dates.length + 6}`).style = footerStyle;
      worksheet.getCell(`B${dates.length + 6}`).value = '';
      worksheet.getCell(`B${dates.length + 6}`).style = footerStyle;
      worksheet.getCell(`A${dates.length + 7}`).value = 'Employee:';
      worksheet.getCell(`B${dates.length + 7}`).value = formattedName;
      worksheet.getCell(`A${dates.length + 7}`).style = footerStyle;
      worksheet.getCell(`B${dates.length + 7}`).style = footerStyle;
      worksheet.getCell(`E${dates.length + 2}`).value = 'Total';
      worksheet.getCell(`E${dates.length + 2}`).style = footerStyle;
      worksheet.getCell(`E${dates.length + 3}`).style = footerStyle;
      worksheet.getCell(`F${dates.length + 2}`).value = totalRequiredHours;
      worksheet.getCell(`F${dates.length + 2}`).style = footerStyle;
      worksheet.getCell(`G${dates.length + 2}`).value = totalWorkingHours;
      worksheet.getCell(`G${dates.length + 2}`).style = footerStyle;
      worksheet.getCell(`H${dates.length + 2}`).value = totalOvertimeHours;
      worksheet.getCell(`H${dates.length + 2}`).style = footerStyle;
      worksheet.getCell(`E${dates.length + 4}`).value = 'Sick Leave Count';
      worksheet.getCell(`E${dates.length + 4}`).style = footerStyle;
      worksheet.getCell(`F${dates.length + 4}`).value = sickLeavesCount;
      worksheet.getCell(`F${dates.length + 4}`).style = footerStyle;
      worksheet.getCell(`E${dates.length + 5}`).value = 'Vacation Count';
      worksheet.getCell(`E${dates.length + 5}`).style = footerStyle;
      worksheet.getCell(`F${dates.length + 5}`).value = holidaysCount;
      worksheet.getCell(`F${dates.length + 5}`).style = footerStyle;
      worksheet.mergeCells(`A${dates.length + 2}:D${dates.length + 5}`);

      worksheet.getCell(`A${dates.length + 2}:D${dates.length + 5}`).value = 'Signature:';
      worksheet.getCell(`A${dates.length + 2}:D${dates.length + 5}`).style = footerStyle;


      worksheet.pageSetup.printArea = `A1:J${dates.length + 7}`;
      worksheet.eachRow({ includeEmpty: true }, (row) => {
        row.eachCell({ includeEmpty: true }, (cell) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        });
      });
      worksheet.mergeCells(`I${dates.length + 2}:J${dates.length + 5}`);

      // Add the logo to the merged cells
      const logoImage = workbook.addImage({
        base64: logoBase64,
        extension: 'png',
      });
      worksheet.addImage(logoImage, `I${dates.length + 2}:J${dates.length + 5}`);
      // Create the Excel file
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileName = 'data.xlsx';
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = URL.createObjectURL(blob);
          a.download = fileName;
          a.click();
          document.body.removeChild(a);
        }
      });
    },
    isWeekend(date) {
      const day = new Date(date).getDay();
      return day === 0 || day === 6; // 0: Sunday, 6: Saturday
    },
    selectMonth(index) {
      this.selectedMonth = index;
    },
    getDatesForSelectedMonth() {
      const year = this.selectedYear;
      const monthIndex = this.selectedMonth;
      const dates = [];

      const startDate = new Date(year, monthIndex, 1);
      const endDate = new Date(year, monthIndex + 2, 0);

      for (let currentDate = startDate; currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
        const date = currentDate.toISOString().split('T')[0];
        dates.push(date);
      }

      // Add the last date of the month
      const lastDate = endDate.toISOString().split('T')[0];
      dates.push(lastDate);

      return dates.filter(date => new Date(date).getMonth() === monthIndex);
    },
    getRequiredHours(date) {
      if (this.isPublicHoliday(date, this.state)) {
        this.status[date] = 'Public Holiday'; // Set the status to 'Public Holiday' for public holidays
      } else if (!this.status[date]) {
        this.status[date] = 'Normal'; // Set the default status to 'Normal' if not already set
      }
      if (this.isWeekendOrPublicHoliday(date)) {
        return ''; // Set weekends and public holidays to an empty string
      } else {
        const dayOfWeek = this.getDayOfWeek(date);
        let requiredHours = this.requiredHours[dayOfWeek]; // Retrieve the required hours for the selected day

        const status = this.status[date];
        if (status === 'Sick' || status === 'Public Holiday' || status === 'Vacation' || status === 'School') {
          requiredHours = 0; // Set required hours to 0 if Sick, Public Holiday, or Vacation
        }

        const hours = Math.floor(requiredHours);
        const minutes = Math.round((requiredHours - hours) * 60);
        return `${hours}h ${minutes}m`; // Return the calculated required hours
      }
    },
    isPublicHoliday(date, state) {
      const stateCodeMap = {
        'Baden-Württemberg': 'BW',
        'Bayern': 'BY',
        'Berlin': 'BE',
        'Brandenburg': 'BB',
        'Bremen': 'HB',
        'Hamburg': 'HH',
        'Hessen': 'HE',
        'Niedersachsen': 'NI',
        'Mecklenburg-Vorpommern': 'MV',
        'Nordrhein-Westfalen': 'NW',
        'Rheinland-Pfalz': 'RP',
        'Saarland': 'SL',
        'Sachsen': 'SN',
        'Sachsen-Anhalt': 'ST',
        'Schleswig-Holstein': 'SH',
        'Thüringen': 'TH'
      };

      const stateCode = stateCodeMap[state];
      if (!stateCode) {
        return false;
      }

      const isHoliday = moment(date).isHoliday(stateCode);
      return isHoliday;
    },
    isWeekendOrPublicHoliday(date) {
      const formattedDate = date.split('T')[0];
      return this.isWeekend(formattedDate) || this.isPublicHoliday(formattedDate);
    },
    calculateOvertimeHours(date) {
      const requiredHours = this.getRequiredHours(date);
      const workingHours = this.calculateWorkingHours(date);
      const isPublicHoliday = this.isPublicHoliday(date);
      const isWeekend = this.isWeekend(date);

      // Check if it's a public holiday or a weekend
      if ((isPublicHoliday || isWeekend) && workingHours !== '' && workingHours !== 'Invalid time') {
        // If it's a public holiday or a weekend and working hours are not empty,
        // return working hours as overtime hours directly
        return workingHours;
      }

      // Otherwise, calculate overtime based on required and working hours
      if (requiredHours !== '' && workingHours !== 'Invalid time' && workingHours !== '') {
        const requiredHoursInMinutes = this.getHoursInMinutes(requiredHours);
        const workingHoursInMinutes = this.getHoursInMinutes(workingHours);
        const overtimeMinutes = workingHoursInMinutes - requiredHoursInMinutes;
        const absoluteOvertimeMinutes = Math.abs(overtimeMinutes);
        const overtimeHours = Math.floor(absoluteOvertimeMinutes / 60);
        const overtimeMinutesRemaining = absoluteOvertimeMinutes % 60;
        const formattedOvertime = `${overtimeMinutes < 0 ? '-' : '\u00A0'}${overtimeHours}h ${overtimeMinutesRemaining}m`;
        return formattedOvertime;
      }
      return '';
    },
    getHoursInMinutes(hours) {
      const [hoursPart, minutesPart] = hours.split('h');
      const hoursInMinutes = parseInt(hoursPart) * 60;
      const minutes = parseInt(minutesPart.replace('m', ''));
      return hoursInMinutes + minutes;
    },
    getMinutesInHours(minutes) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours}h ${remainingMinutes}m`;
    },
    getDayOfWeek(date) {
      const day = new Date(date).getDay();
      const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      return weekdays[day];
    },
    getHours() {
      return this.$xhr.get(`/api/fixhours?populate=*&filters[user_id][$eq]=${this.$route.params.id}`)

    },
    async getUser() {
      const response = await this.$xhr.get(`/api/users?populate=*&filters[id][$eq]=${this.$route.params.id}`);
      const userData = response.data[0];
      this.userEmail = userData.email; // Store the user's email
    },
    makeUpdateToast() {
      if (this.isToastVisible) {
        return;
      }
      this.isToastVisible = true;
      this.$bvToast.toast("Timing Successfully updated!", {
        title: "Success",
        variant: "success",
        autoHideDelay: 1000,
        solid: true,
      });
      setTimeout(() => {
        this.isToastVisible = false;
      }, 1000);
    },
    getData() {
      return this.$xhr.get(`/api/timings?filters[user_id][$eq]=${this.$route.params.id}`);

    },
    reloadData() {
      return this.$xhr.get(`/api/timings?filters[user_id][$eq]=${this.$route.params.id}`)
        .then((resp) => {
          this.list = resp.data.data;
        });
    },
    addTotal() {
      const monthData = this.monthsData[this.selectedMonth];
      const year = this.selectedYear;
      const month = this.months[this.selectedMonth].name;
      const userId = this.$route.params.id;

      const data = {
        totalRequiredHours: monthData.requiredHours,
        totalWorkingHours: monthData.workingHours,
        sickLeavesCount: monthData.sickLeaves,
        totalOvertimeHours: monthData.overtimeHours,
        holidaysCount: monthData.holidays,
        month: month,
        year: year,
        user_id: userId,
        users_permissions_user: { id: userId }
      };

      // Check for existing row with the same month, year, and user_id on the backend
      this.$xhr.get(`api/overviews?filters[month][$eq]=${month}&filters[year][$eq]=${year}&filters[user_id][$eq]=${userId}`)
        .then(response => {
          const existingRow = response.data.data[0];

          if (existingRow) {
            const id = existingRow.id;
            this.$xhr.put(`api/overviews/${id}`, { data })
              .then(response => {
                console.log('Data updated successfully:', response.data);
              })
              .catch(error => {
                console.error('Error updating data:', error);
              });
          } else {
            this.$xhr.post('api/overviews', { data })
              .then(response => {
                console.log('Data saved successfully:', response.data);
              })
              .catch(error => {
                console.error('Error saving data:', error);
              });
          }
        })
        .catch(error => {
          console.error('Error retrieving data:', error);
        });
    },
    async addTime(date) {
      const startTime = this.startTimes[date];
      const endTime = this.endTimes[date];
      const breaks = this.breaks[date];
      const status = this.status[date];
      const customStatus = this.customStatus[date];

      const data = {
        date,
        startTimes: startTime,
        endTimes: endTime,
        breaks,
        status,
        customStatus,
        user_id: this.$route.params.id,
        users_permissions_user: { id: this.$route.params.id }
      };

      const [found] = JSON.parse(JSON.stringify(this.list)).filter(row => row.attributes.date === date);

      try {
        let response;
        if (found) {
          response = await this.$xhr.put(`api/timings/${found.id}`, { data });
          console.log('Data updated successfully:', response.data);
          this.makeUpdateToast(true);
        } else {
          response = await this.$xhr.post('api/timings', { data });
          console.log('Data saved successfully:', response.data);
          this.$bvToast.toast('Timings added successfully!', {
            title: 'Success',
            variant: 'success',
            solid: true,
            autoHideDelay: 3000,
          });
        }

        // If the response is not successful, show a warning toast
        if (!response || !response.data) {
          this.$bvToast.toast('Failed to save timings. Please try again.', {
            title: 'Warning',
            variant: 'warning',
            solid: true,
            autoHideDelay: 3000,
          });
        }

      } catch (error) {
        console.error('Error saving data:', error);
        this.$bvToast.toast('An error occurred while saving timings. Please try again.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
          autoHideDelay: 3000,
        });
      }
    }, 
    async handleButtonClick() {
      this.addTotal();
      const dates = this.getDatesForSelectedMonth();

      // Loop through each row and save the data if start and end times are present
      for (const date of dates) {
        if (
          this.status[date] === 'Sick' ||
          this.status[date] === 'Vacation' ||
          this.status[date] === 'Public Holiday' ||
          this.status[date] === 'Overtime reduction' ||
          this.status[date] === 'Free' ||
          this.status[date] === 'School' ||
          this.status[date] === 'Custom'
        ) {
          await this.addTime(date);
        } else if (this.startTimes[date] && this.endTimes[date]) {
          await this.addTime(date);
        }
      }

      // Refresh data after handling all dates
      await this.reloadData();
    },

  },
  mounted() {

    this.reloadData();
    this.getData().then(response => {
      const data = response.data.data;
      console.log(data, 'abcdf')
      this.list = data;

      if (Array.isArray(data)) {
        data.forEach(entry => {
          const date = entry.attributes.date;
          this.startTimes[date] = entry.attributes.startTimes;
          this.endTimes[date] = entry.attributes.endTimes;
          this.breaks[date] = entry.attributes.breaks;
          this.status[date] = entry.attributes.status;
          this.customStatus[date] = entry.attributes.customStatus;
        });
      } else {
        console.error('Fetched data is not an array:', data);
      }
    }).catch(error => {
      console.error('Failed to fetch data:', error);
    });
    this.getHours().then(response => {
      let hoursData = response.data.data;
      hoursData = JSON.parse(JSON.stringify(hoursData))
      if (Array.isArray(hoursData) && hoursData.length > 0) {
        const requiredHours = hoursData[0].attributes.requiredHours;
        const state = hoursData[0].attributes.state;
        this.state = state; // Set the state value here
        this.requiredHours = requiredHours;
        console.log(this.requiredHours, this.state, 'aaaaa')

      } else {
        console.error('No hours data found');
      }
    }).catch(error => {
      console.error('Failed to fetch hours data:', error);
    });
  },
};

</script>

<style scoped>
.table-style {
  width: 85%;
  margin-left: 150px;
}

.progress {
  height: 36px;
  width: 85%;
  margin-left: 148px;
}

.progress-bar {
  text-align: center;
  line-height: 20px;
  font-weight: bold;
}

.progress-bar-orange {
  background-color: #E2923D;
}

.progress-bar-green {
  background-color: green;
}
</style>
