import { createRouter, createWebHashHistory } from 'vue-router';
import Home from './pages/Home.vue';
import Login from '@lib/frontend/pages/Login.vue';
import Adminoverview from './pages/Adminoverview.vue';
import General from './pages/General.vue';
import Months from './pages/Months.vue';
import Overview from './pages/Overview.vue';
import Teamoverview from './pages/Teamoverview.vue';
import Sproof from './pages/Sproof.vue';
import Host from './pages/Host.vue'
import Schweiz from './pages/Schweiz.vue'




const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/eznw',
    name: 'Host',
    component: Host,
    meta: { requiresAdmin: true },
  },
  {
    path: '/schweiz',
    name: 'Schweiz',
    component: Schweiz,
    meta: { requiresAdmin: true },
  },
  {
    path: '/sproof',
    name: 'Sproof',
    component: Sproof,
    meta: { requiresAdmin: true },

  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/user/:id/settings',
    name: 'General',
    component: General,
    meta: { requiresAuth: true },
  },
  {
    path: '/user/:id/hours/:year?/:month?',
    name: 'Months',
    component: Months,
    meta: { requiresAuth: true }, 
  },
  {
    path: '/user/:id/overview',
    name: 'Overview',
    component: Overview,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin',
    name: 'Adminoverview',
    component: Adminoverview,
    meta: { requiresAdmin: true },
  },
  {
    path: '/teamoverview',
    name: 'Teamoverview',
    component: Teamoverview,
    meta: { requiresAdmin: true }, 
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('authToken');
  const userProfile = JSON.parse(localStorage.getItem('userProfile')) || {};
  const isAdmin = userProfile.role && userProfile.role.name === 'Admin';
  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login');
  } else if (to.meta.requiresAdmin && !isAdmin) {
    next('/');
  } else if (to.meta.requiresAuth && to.params.id && String(to.params.id) !== String(userProfile.id) && !isAdmin) {
    next('/');
  } else {
    next();
  }
});

export default router;

