import { createApp, ref } from 'vue'
import App from './App.vue'
import router from './router'
import VueAxios from 'vue-axios'
import VCalendar from 'v-calendar';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import config from "@lib/frontend/config";
import xhr from "@lib/frontend/xhr";
import * as composable from "@lib/frontend/composable"
import './main.css'
import axios from 'axios';




console.log("ENV", process.env)
console.log("CONFIG", config)

const user = composable.useUser()

async function boot(){

  const app = createApp(App)
  app.config.warnHandler = () => {}; // Disabled Warnings
  app.config.unwrapInjectedRef = true
  app.use(VueAxios, {$xhr: xhr},axios)
  app.use(BootstrapVue)
  app.use(router)
  app.use(VCalendar, {})

  if(localStorage.getItem('jwt')){
    try {
      await user.loginJwt(localStorage.getItem('jwt'))
    } catch(e){
      console.error("Cached login failed, jwt invalid", e)
    }
  }

  router.beforeEach((to, from) => {
    if(to.name == 'login' || to.name == 'home'){
      return true
    }
    if(!user.profile.value){
      console.log("Login required")
      return {name: 'login'}
    }
    return true
  })

  app.provide('user', user)
  app.provide('config', config)
  app.mount('#app')
}


boot()
  .then(()=> console.log("BOOTED"))
  .catch((e)=>console.error("BOOT ERROR",e))


