<template>

    <div class="home">
        <img class="logo" alt="Vue logo" src="@lib/frontend/assets/new.png">
        <h1>Welcome to Time Change Time Sheets Application </h1>
    </div>
</template>

<script>
// @ is an alias to /src
import {inject} from "vue";

export default {
    name: 'Homeview',
  
    setup(){
        const {profile} = inject('user')
        return {
            profile
        }
    },
    mounted() {
        let user = localStorage.getItem('user-info');
        if (!user) {
            this.$router.push({
                name: 'home'
            })
        }
    }
}
</script>