import jsPDF from 'jspdf';
import 'jspdf-autotable';
import imagePath from './image.png';

export const generatePDF = (host) => {
  const filteredProjects = host.projects.filter(project => {
    const delta = parseFloat(project.Delta);
    return delta !== 0 && delta < 0; // Include only rows where Delta is negative
  });

  if (filteredProjects.length === 0) {
    console.log(`No projects with Delta < 0 for ${host.Name} ${host.First}, PDF not generated.`);
    return null;
  }

  const containsJune2024OrHigher = filteredProjects.some(project => {
    const dateParts = project['Date '].split('.');
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10);
    const year = parseInt(dateParts[2], 10);
    return year > 2024 || (year === 2024 && month >= 6);
  });

  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'mm',
    compress: true,
  });

  const imageWidth = 30;
  const imageHeight = 10;
  const pageWidth = doc.internal.pageSize.width;
  const imageX = pageWidth - imageWidth - 10;
  const imageY = 5;

  const title = "Einsatz Zeitnachweis";
  const headers = [
    [
      'Name, First',
      'PN',
      'Office',
      'Project No',
      'Place',
      'Date',
      'Start',
      'End',
      'Duration',
      'Break',
      'Working Hours Net',
      'Salary per hour',
      'Salary total',
    ]
  ];

  const values = filteredProjects.map(project => [
    `${host.Name} ${host.First}`,
    project.PN,
    project.Office,
    project['Project No'] || '',
    project.Place,
    project['Date '] || '',
    project.Start,
    project.End,
    project.Duration,
    project['Break'] || '',
    project['Working Hours Net'] || '',
    project['Salary per hour'] || '',
    project['Total'] || ''
  ]);

  let totalPagesExp = "{total_pages_count_string}";

  const addPageNumbers = () => {
    let str = "Page " + doc.internal.getNumberOfPages();
    if (typeof doc.putTotalPages === 'function') {
      str = str + " of " + totalPagesExp;
    }
    doc.setFontSize(8);
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    doc.text(str, pageWidth / 2, pageHeight - 10, { align: 'center' });
  };

  doc.setFontSize(6);

  const defaultText = [
    "Dear employee, Please find enclosed your monthly overview of assignments. If everything is correct, please sign it. If an error has crept in, please contact us: accounting@time-change.services. We will correct this with the following payroll run.",
    "Please sign this document anyway. The correction will also be listed in the next payroll run. We will then pay this out with the pay run for the month in question. If you have any further questions, please contact us: dispo@time-change.services",
    "Kind regards, Your Time Change Service & Logistics Team"
  ];

  const updatedText = [
    "Dear employee, Please find enclosed your monthly overview of assignments. This is the basis for the salary run this month. If there is a mistake e.g. in timing or one assignment is missing, please contact us till the 7th of this month. We will update and include it in the current salary run.",
    "If you contact us after the 7th all adjustments will be postponed in the next run. If everything is correct, please sign it. If an error occured, please contact us: accounting@time-change.services. If you have any further questions, please contact us: dispo@time-change.services",
    "Kind regards, Your Time Change Service & Logistics Team"
  ];

  const letterText = containsJune2024OrHigher ? updatedText : defaultText;

  const lineHeight = 5;
  let textY = 20;
  letterText.forEach(line => {
    doc.text(line, 10, textY);
    textY += lineHeight;
  });

  doc.autoTable({
    head: headers,
    body: values,
    startY: textY + 5,
    didDrawPage: (data) => {
      doc.addImage(imagePath, 'PNG', imageX, imageY, imageWidth, imageHeight);
      doc.setFontSize(12);
      const titleWidth = doc.getTextWidth(title);
      const titleX = (pageWidth - titleWidth) / 2;
      doc.text(title, titleX, 10);
      addPageNumbers();
    },
    headStyles: {
      fillColor: [226,146,61],
    },
    styles: {
      fontSize: 7,
    },
    margin: { top: 25 }
  });

  const parseSalary = (salaryString) => {
    if (!salaryString) {
      return 0;
    }
    const sanitizedString = salaryString.replace(/,/g, ''); 
    const parsedValue = parseFloat(sanitizedString);
    return parsedValue;
  };

  const totalSalary = filteredProjects.reduce((sum, project) => {
    const salaryTotal = parseSalary(project['Total']) || 0;
    return sum + salaryTotal;
  }, 0).toFixed(2);

  const pageHeight = doc.internal.pageSize.height;
  const leftMargin = 14;
  const rightMargin = pageWidth - 14;

  const saldoY = doc.lastAutoTable.finalY + 10;
  const lineSpacing = 15;

  doc.setFontSize(8);
  doc.text("Saldo:", rightMargin - 22, saldoY);
  doc.text(`${totalSalary} €`, rightMargin - doc.getTextWidth(`${totalSalary} €`), saldoY);

  const nextLineY = saldoY + lineSpacing;
  doc.text("Unterschrift Arbeitnehmer", leftMargin, nextLineY + 35);
  doc.setTextColor(255);
  doc.text(`{sproof{${host.First},${host.Name},${host.Email},1}sproof}`, leftMargin, nextLineY + 25);
  doc.setTextColor(0); 

  const footerStartY = pageHeight - 35;
  doc.setFontSize(8);
  doc.text("Time Change Service Logistik GmbH", leftMargin, footerStartY + 30);
  doc.text("Gerichtsstand Berlin", rightMargin - doc.getTextWidth("Gerichtsstand Berlin"), footerStartY + 20);
  doc.text("AG Charlottenburg HRB 167816B", rightMargin - doc.getTextWidth("AG Charlottenburg HRB 167816B"), footerStartY + 25);
  doc.text("Geschäftsführer: Marko Hafemann", rightMargin - doc.getTextWidth("Geschäftsführer: Marko Hafemann"), footerStartY + 30);

  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }

  return doc.output('arraybuffer');
};
