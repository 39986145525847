<template>
  <Header />
  <router-view />
  <!-- <Footer/> -->
</template>

<script>
import { computed, inject } from 'vue'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'


export default {
  name: 'App',
  components:{
    Header,
    Footer
  },
  setup(){
    const {profile} = inject('user')
    return {
      profile
    }
  },
}
</script>

