<template>
  <div class="navigation">
    <template v-if="profile && profile.role && profile.role.name === 'Admin'">
      <router-link to="/admin">Admin Overview</router-link>
      <router-link to="/teamoverview">Team Overview</router-link>
      <router-link to="/eznw">Einsatz Zeitnachweis</router-link>
      <router-link to="/schweiz">Schweiz</router-link>
    </template>
    <router-link v-if="profile" :to="{ name: 'General', params: { id: profile.id }}">Settings</router-link>
    <router-link v-if="profile" :to="{ name: 'Months', params: { id: profile.id }}">Time Sheet</router-link>
    <router-link v-if="profile" :to="{ name: 'Overview', params: { id: profile.id }}">Overview</router-link>
    <!-- <router-link v-if="profile" :to="{ name: 'Timetracker', params: { id: profile.id }}">Time Tracker</router-link> -->


    <!-- <img src="@lib/frontend/assets/tcwhite.png" class="tcwhite"> -->
    
    <template v-if="profile">
      <a href="#" v-on:click="logout">Logout ({{profile.email}})</a>
    </template>
    <template v-else>
      <b-link :to="{name: 'login'}">Please login</b-link>
    </template>
  </div>
</template>

<script>
import { inject } from 'vue'

export default {
  name: "Header",
  setup() {
    const { profile, logout } = inject('user')
    return {
      profile,
      logout
    }
  },
};
</script>
