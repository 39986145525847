<template>
  <div>
    <b-card class="profilecard">
      <template class="ca" v-slot:header>
        <img src="@lib/frontend/assets/tc.png" class="tclogo">
        <h4>User Profile</h4>
      </template>
      <ul class="list-group list-group-flush">
        <li v-for="item in list" :key="item.id" class="list-group-item">
          <div>
            <strong>ID:</strong> {{ item.id }}
          </div>
          <div>
            <strong>Name:</strong> {{ formatName(item.username) }}
          </div>
          <div>
            <strong>Email:</strong> {{ item.email }}
          </div>
          <div>
            <strong>Team:</strong> {{ item.office }}
          </div>
        </li>
      </ul>
    </b-card>
  </div>
</template>

<script>
import { inject } from 'vue'
export default {
  name: 'Userprofile',
  data() {
    return {
      list: [],
    }
  },
  methods: {
    getData() {
      this.$xhr.get(`/api/users?populate=*&filters[id][$eq]=${this.$route.params.id}`).then((result) => {
        this.list = result.data
        console.log('aasadsdsd', this.$route.params.id)
      })
    },
    formatName(username) {
      const parts = username.split('@')[0].split('.')
      const formattedName = parts.map((part) => part.charAt(0).toUpperCase() + part.slice(1)).join(' ')
      return `${formattedName}`
    },
  },
  mounted() {
    this.getData()
  },
}
</script>
