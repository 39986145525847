import jsPDF from 'jspdf';
import 'jspdf-autotable';
import imagePath from './image.png';
import moment from 'moment';


export const generatePDF = (host) => {
  const filteredProjects = host.projects.filter(project => {
    const delta = parseFloat(project.Delta);
    return delta !== 0 && delta < 0; // Include only rows where Delta is negative
  });

  if (filteredProjects.length === 0) {
    console.log(`No projects with Delta < 0 for ${host.Name} ${host.First}, PDF not generated.`);
    return null;
  }

  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'mm',
    compress: true,
  });

  const imageWidth = 40;
  const imageHeight = 15;
  const pageWidth = doc.internal.pageSize.width;
  const imageX = pageWidth - imageWidth - 10;
  const imageY = 5;
  const getHighestMonth = (dateStrings) => {
    const formats = ['DD.MM.YYYY', 'M/D/YYYY', 'MM/DD/YYYY', 'YYYY-MM-DD', 'D/M/YYYY', 'DD/MM/YYYY', 'M/D/YY'];

    // German month names
    const germanMonths = [
      'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
      'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
    ];

    const validDates = dateStrings.map(dateStr => {
      const date = moment(dateStr, formats, true);
      return date.isValid() ? date : null;
    }).filter(date => date !== null);

    // Sort valid dates in descending order and take the highest one
    validDates.sort((a, b) => b - a);
    const highestDate = validDates[0];

    if (highestDate) {
      const monthIndex = highestDate.month();
      const year = highestDate.year();
      return `${germanMonths[monthIndex]} ${year}`;
    }

    return 'Ungültiges Datum';
  };

  const pdfMonth = getHighestMonth(filteredProjects.map(project => project['Date '])); // Get the month for the title
  const title = `Liste der abgerechneten Einsätze ${pdfMonth}`;
  const headers = [
    [
      'Name, First',
      // 'PN',
      'Office',
      'Cruise',
      'Place',
      'Date',
      'Month',
      'Earned Payroll',
      'RK 2.6%',
      'RK 3.8%',
      'RK 8.1%',
      'Verpfl.Pauschale',
      'RK Total',
      'Salary total',
    ]
  ];

  const values = filteredProjects.map(project => [
    `${host.Name} ${host.First}`,
    // project.PN,
    project.Office,
    project['Project No'] || '',
    project.Port,
    project['Date '] || '',
    project.Month,
    project['Earned Payroll'] || '',
    project['RK 2,6%'] || '',
    project['RK 3,8%'] || '',
    project['RK 8,1%'] || '',
    project['Verpfl Pauschale'] || '',
    project['RK Total'] || '',
    project['Total Earning'] || ''
  ]);

  let totalPagesExp = "{total_pages_count_string}";

  const addPageNumbers = () => {
    let str = "Page " + doc.internal.getNumberOfPages();
    if (typeof doc.putTotalPages === 'function') {
      str = str + " of " + totalPagesExp;
    }
    doc.setFontSize(8);
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    doc.text(str, pageWidth / 2, pageHeight - 10, { align: 'center' });
  };

  doc.setFontSize(6);

  let textY = 20;

  doc.autoTable({
    head: headers,
    body: values,
    startY: textY + 5,
    didDrawPage: (data) => {
      doc.addImage(imagePath, 'PNG', imageX, imageY, imageWidth, imageHeight);
      doc.setFontSize(12);
      const titleWidth = doc.getTextWidth(title);
      const titleX = (pageWidth - titleWidth) / 2;
      doc.text(title, titleX, 10);
      addPageNumbers();
    },
    headStyles: {
      fillColor: [226, 146, 61],
    },
    styles: {
      fontSize: 8.5, // Increased font size for the cell values
    },
    margin: { top: 25 }
  });

  const parseSalary = (salaryString) => {
    if (!salaryString) {
      return 0;
    }
    const sanitizedString = salaryString.replace(/,/g, '');
    const parsedValue = parseFloat(sanitizedString);
    return parsedValue;
  };

  const totalSalary = filteredProjects.reduce((sum, project) => {
    const salaryTotal = parseSalary(project['Total Earning']) || 0;
    return sum + salaryTotal;
  }, 0).toFixed(2);

  const pageHeight = doc.internal.pageSize.height;
  const leftMargin = 14;
  const rightMargin = pageWidth - 14;

  const saldoY = doc.lastAutoTable.finalY + 10;
  const lineSpacing = 15;

  doc.setFontSize(8);
  doc.text("Saldo:", rightMargin - 26, saldoY);
  doc.text(`${totalSalary} CHF`, rightMargin - doc.getTextWidth(`${totalSalary} CHF`), saldoY);

  const nextLineY = saldoY + lineSpacing;
  doc.text("", leftMargin, nextLineY + 35);
  doc.setTextColor(255);
  doc.setTextColor(0);

  const footerStartY = pageHeight - 35;
  doc.setFontSize(8);
  doc.text("Time Change Service Logistik GmbH", leftMargin, footerStartY + 30);
  doc.text("Gerichtsstand Berlin", rightMargin - doc.getTextWidth("Gerichtsstand Berlin"), footerStartY + 20);
  doc.text("AG Charlottenburg HRB 167816B", rightMargin - doc.getTextWidth("AG Charlottenburg HRB 167816B"), footerStartY + 25);
  doc.text("Geschäftsführer: Marko Hafemann", rightMargin - doc.getTextWidth("Geschäftsführer: Marko Hafemann"), footerStartY + 30);

  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }

  return doc.output('arraybuffer');
};
