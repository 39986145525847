<template>
  <div class="container">
    <div class="card text-center mt-5">
      <div class="card-header bg-warning text-white">
        <h1 class="card-title mb-0">Schweizer Guides</h1>
      </div>
      <div class="card-body">
        <textarea v-model="customMessage" rows="5" class="form-control mb-3"></textarea>
        <div class="file-upload">
          <input type="file" class="form-control-file mb-3" ref="fileInput" @change="handleFileUpload" accept=".xlsx, .xls" />
          <b-button class="btn btn-success mb-3" @click="downloadZip" :disabled="!isFileUploaded">
            <b-spinner v-if="isProcessingZip" small type="grow"></b-spinner>
            Download ZIP
          </b-button>
          <b-button class="btn btn-primary mb-3" @click="sendAllEmails" :disabled="!isFileUploaded">
            <b-spinner v-if="isSendingEmails" small type="grow"></b-spinner>
            Send Emails
          </b-button>
          <div class="progress" v-if="showProgressBar">
            <div class="progress-bar progress-bar-striped bg-success" role="progressbar" :style="{ width: progress + '%' }" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100">{{ progress }}%</div>
          </div>
        </div>
        <div v-if="inconsistentPNs.length > 0" class="alert alert-danger mt-3">
          <strong>Warning!</strong> The following PN numbers have inconsistent email addresses:
          <ul>
            <li v-for="pn in inconsistentPNs" :key="pn">{{ pn }}</li>
          </ul>
        </div>
        <div v-if="logs.length > 0" class="alert alert-info mt-3">
          <strong>Logs:</strong>
          <ul>
            <li v-for="log in logs" :key="log" :class="getLogClass(log)">
              {{ log }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import * as XLSX from 'xlsx';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { generatePDF } from '../utils/pdfGeneratorswizz';
import axios from 'axios';

const customMessage = ref(`Guten Tag,
Anbei senden wir Dir die Übersicht, der mit der aktuellen Lohnzahlung erfassten und abgerechneten Einsätze.

Beste Grüße
`);

const jsonData = ref(null);
const isFileUploaded = ref(false);
const showProgressBar = ref(false);
const progress = ref(0);
const fileInput = ref(null);
const isProcessingZip = ref(false);
const isSendingEmails = ref(false);
const inconsistentPNs = ref([]);
const logs = ref([]);

// Helper Functions
const getLogClass = (log) => (log.includes('PDF not generated') ? 'text-danger' : '');
const arrayBufferToBase64 = (buffer) => window.btoa(String.fromCharCode(...new Uint8Array(buffer)));

// Function to get the highest month from date strings
const getHighestMonth = (dateStrings) => {
  const formats = ['DD.MM.YYYY', 'M/D/YYYY', 'MM/DD/YYYY', 'YYYY-MM-DD', 'D/M/YYYY', 'DD/MM/YYYY', 'M/D/YY'];
  const germanMonths = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

  const validDates = dateStrings.map(dateStr => moment(dateStr, formats, true)).filter(date => date.isValid()).sort((a, b) => b - a);
  const highestDate = validDates[0];

  return highestDate ? `${germanMonths[highestDate.month()]} ${highestDate.year()}` : 'Ungültiges Datum';
};

// File Upload Handler
const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    isProcessingZip.value = true;
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets['Einsätze'];
      if (worksheet) {
        jsonData.value = XLSX.utils.sheet_to_json(worksheet, { raw: false, cellFormula: false }).slice(0, 8000);
        inconsistentPNs.value = checkForInconsistentEmails(jsonData.value);
        isFileUploaded.value = true;
        isProcessingZip.value = false;
      } else {
        alert("Sheet 'Einsätze' not found!");
        isProcessingZip.value = false;
      }
    };
    reader.readAsArrayBuffer(file);
  }
};

// Check for inconsistent emails
const checkForInconsistentEmails = (data) => {
  const pnEmails = data.reduce((acc, curr) => {
    if (!acc[curr.PN]) acc[curr.PN] = new Set();
    acc[curr.PN].add(curr.Email);
    return acc;
  }, {});
  return Object.keys(pnEmails).filter(pn => pnEmails[pn].size > 1);
};

// Email Sending Function
const sendEmail = async (recipientEmail, subject, message, attachment) => {
  const accessToken = localStorage.getItem('authToken');
  if (!accessToken) {
    logs.value.push(`Error: Access token is missing for ${recipientEmail}. Please login again.`);
    return false;
  }

  const emailData = {
    message: {
      subject,
      body: { contentType: "Text", content: message },
      toRecipients: [{ emailAddress: { address: recipientEmail } }],
      attachments: [attachment],
    },
  };

  try {
    await axios.post('https://graph.microsoft.com/v1.0/me/sendMail', emailData, {
      headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' },
    });
    logs.value.push(`Email sent successfully to ${recipientEmail}`);
    return true; // Email sent successfully
  } catch (error) {
    const errorMessage = error.response
      ? `Error sending email to ${recipientEmail}: Token is invalid or expired, please logout and login again with accountingtcs@time-change.services`
      : `Error sending email to ${recipientEmail}`;
      
    logs.value.push(errorMessage); // Log the error message to logs
    return false; // Email failed to send
  }
};

// Function to Generate PDFs and Download as ZIP
const generatePDFsAndZip = async (data) => {
  const zip = new JSZip();
  let processedItems = 0;
  const uniquePNs = [...new Set(data.map(item => item.PN))];

  for (const pn of uniquePNs) {
    const hostData = data.filter(item => item.PN === pn);
    if (hostData.length > 0) {
      const host = {
        Name: hostData[0].Name,
        First: hostData[0].First,
        PN: hostData[0].PN,
        Office: hostData[0].Office,
        Email: hostData[0].Email,
        projects: hostData,
      };

      if (host.Email === '0') {
        logs.value.push(`Skipping host: ${host.Name} ${host.First} due to Email being '0'`);
        continue;
      }

      const pdfData = await generatePDF(host);
      if (pdfData) {
        const highestMonth = getHighestMonth(host.projects.filter(project => parseFloat(project.Delta) !== 0).map(project => project['Date ']));
        const fileName = `${host.Name}_${host.PN}_Lohnabrechnung_${highestMonth}.pdf`;
        zip.file(fileName, pdfData);
      } else {
        logs.value.push(`PDF not generated for: ${host.Name} ${host.First}`);
      }
    }
    processedItems++;
    progress.value = Math.round((processedItems / uniquePNs.length) * 100);
  }
  return zip.generateAsync({ type: 'blob' });
};

// Download ZIP Handler
const downloadZip = async () => {
  if (jsonData.value) {
    progress.value = 0;
    showProgressBar.value = true;
    isProcessingZip.value = true;
    const zipContent = await generatePDFsAndZip(jsonData.value);
    saveAs(zipContent, 'Guides.zip');
    showProgressBar.value = false;
    isProcessingZip.value = false;
    resetFileInput();
  }
};

// Send Emails Handler
const sendAllEmails = async () => {
  if (jsonData.value) {
    progress.value = 0;
    showProgressBar.value = true;
    isSendingEmails.value = true;
    logs.value = []; // Reset logs before sending emails

    const uniquePNs = [...new Set(jsonData.value.map(item => item.PN))];
    let emailsSent = 0;

    for (const pn of uniquePNs) {
      const hostData = jsonData.value.filter(item => item.PN === pn);
      if (hostData.length > 0) {
        const host = {
          Name: hostData[0].Name,
          First: hostData[0].First,
          PN: hostData[0].PN,
          Office: hostData[0].Office,
          Email: hostData[0].Email,
          projects: hostData,
        };

        if (host.Email === '0') {
          logs.value.push(`Skipping host: ${host.Name} ${host.First} due to Email being '0'`);
          continue;
        }

        const pdfData = await generatePDF(host);
        if (pdfData) {
          const highestMonth = getHighestMonth(host.projects.filter(project => parseFloat(project.Delta) !== 0).map(project => project['Date ']));
          const fileName = `${host.Name}_${host.PN}_Lohnabrechnung_${highestMonth}.pdf`;
          const pdfBase64 = arrayBufferToBase64(pdfData);
          const attachment = {
            "@odata.type": "#microsoft.graph.fileAttachment",
            name: fileName,
            contentBytes: pdfBase64,
          };

          const personalizedMessage = customMessage.value.replace(/{Name}/g, host.Name);
          const emailSent = await sendEmail(host.Email, "Your Payroll PDF", personalizedMessage, attachment);

          // Update progress bar
          emailsSent++;
          progress.value = Math.round((emailsSent / uniquePNs.length) * 100);
        } else {
          logs.value.push(`PDF not generated for: ${host.Name} ${host.First}`);
        }
      }
    }

    showProgressBar.value = false;
    isSendingEmails.value = false;
  }
};

const resetFileInput = () => {
  fileInput.value.value = '';
  isFileUploaded.value = false;
};
</script>



<style scoped>
.alert {
  margin-top: 20px;
}
.card-title {
  font-size: 24px;
  font-weight: bold;
}

.container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.card {
  width: 60%;
}
</style>
